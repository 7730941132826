.member-card {
  --member-card-primary-color: #222222;
  --member-card-secondary-color: #b3cff8;
  --member-card-highlight-color: #cfe2f3;
  --member-card-background-color: #c5d2e6;
  --member-card-background-color-light: #e3ebf7;
  --member-card-border-color: #eeeeee;

  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem;
  margin: 1rem;
  width: 96%;
  text-align: left;
  background-color: var(--member-card-background-color);
  color: var(--member-card-primary-color);
}

.members-head {
  display: flex;
  justify-content: flex-end;
  background-color: black;
  color: white;
  padding: 0.5rem;
}

.admin-name {
  margin: 0;
}

h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

.logout-btn {
  background-color: var(--member-card-primary-color);
  border-color: var(--member-card-primary-color);
  color: white;
  cursor: pointer;
  padding: 0.1rem;
}

.logout-btn:hover {
  background-color: #f85f06;
  color: darkgray;
}

.member-table {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--member-card-primary-color);
  vertical-align: top;
  caption-side: bottom;
  border-collapse: collapse;
  border: 1px solid var(--member-card-border-color);
}

.member-table tbody tr td,
.member-table tbody tr th {
  padding: 0.2rem 0.4rem;
}

.member-table tbody tr:hover {
  background-color: var(--member-card-highlight-color);
}

.member-table tbody tr:nth-of-type(odd) {
  background-color: var(--member-card-background-color-light);
}
.member-table tbody tr:nth-of-type(odd):hover {
  background-color: var(--member-card-highlight-color);
}

.thead-light {
  background-color: var(--member-card-secondary-color);
  border-bottom: 1px solid #ddd;
}

@media only screen and (orientation: portrait) {
  .member-table {
    overflow-x: auto;
  }

  .member-card {
    width: auto;
  }

  .view-members-wrapper {
    max-width: 92% !important;
    align-items: stretch !important;
  }
}
