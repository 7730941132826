.contact__infodiv {
  text-align: left;
  margin: 0 1rem;
}

.contact__title {
  font-size: larger;
  font-weight: bold;
}

.contact__infodiv > p {
  margin: 0.2rem auto;
}

.contact__infodiv > p:first-child {
  margin: 0.5rem auto 1rem auto;
}

.contact__infodiv > p:last-child {
  margin: 0 auto 0.5rem;
}

.contact-image-wrapper {
  flex-grow: 1;
  margin: 0 1rem;
}

@media only screen and (orientation: portrait) {
  .contact-image-wrapper img {
    width: 100%;
    height: auto;
  }
}