@import "../styles/global-variables.css";

.main-container {
  width: 100%;
}

.sub-navbar {
  display: flex;
  justify-content: flex-start;
  background-color: var(--background-primary-color);
  padding: 0;
}

.sub-navbar-btn {
  padding: 1rem;
  margin: 0;
  cursor: pointer;
  color: var(--special-text-primary-color);
  transition: background-color 0.2s ease-in-out;
}

.sub-navbar-btn:hover {
  background-color: var(--text-highlight-background-color);
}

.sub-navbar-btn.active {
  background-image: radial-gradient(
    var(--linear-primary-color),
    var(--linear-secondary-color)
  );
}

.element-container {
  border-width: 0 2px 2px 2px;
  border-color: transparent 
    var(--linear-secondary-color)
    var(--linear-secondary-color);
  border-style: solid;
  border-radius: 0 0 5px 5px;
  padding: 1rem;
  min-height: 200px;
}

@media only screen and (orientation: portrait) {
  .sub-navbar {
    flex-direction: column;
  }
}