.active-course-item {
  position: relative;
  float: left;
  width: 100%;
  display: block;
}

.active-course-caption {
  position: absolute;
  right: 15%;
  bottom: 5rem;
  left: 15%;
  z-index: 10;
  padding: 1rem;
  color: #fff;
  text-align: left !important;
}

.active-course-img {
  display: block;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  object-position: center;
}

.active-course-caption > .reusable-button-link {
  padding: 0.2rem 0.4rem;
  margin: 1rem 0 2rem 0;
}

@media only screen and (orientation: portrait) {
  .active-course-caption,
  .active-course-item {
    display: inline-block;
  }
  .active-course-img {
    height: 24rem;
  }
  .active-course-caption {
    padding: 0 0.4rem 0.8rem 0.4rem;
    inset: 0.5rem 2% auto 2%;
    overflow: hidden;
  }

  .active-course-caption h1,
  .active-course-caption p,
  .active-course-caption .reusable-button-link {
    margin: 1rem 0;
  }
}
