@import "./global-variables.css";

.navbar {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 3.5rem;
  z-index: 999;
}

.navbar-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-link {
  float: left;
  font-size: larger;
  padding: 1rem;
  display: block;
  color: var(--special-text-primary-color);
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.main-nav-link {
  background-color: var(--background-primary-color);
}

.link__icon {
  height: 1.5rem;
  width: 1.5rem;
}

.menu-item-with-sub-items-down-arrow, 
.menu-item-with-sub-items-up-arrow {
  position: relative; /* Enable positioning for pseudo-elements */
  cursor: pointer;
}

.menu-item-with-sub-items-down-arrow::after {
  content: "▾";
}

.menu-item-with-sub-items-up-arrow::after {
  content: "▴";
}

.menu-item-with-sub-items-down-arrow::after,
.menu-item-with-sub-items-up-arrow::after {
  font-size: 0.8rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 0.3rem;
  font-weight: bold;
}

.submenu {
  padding: 0;
  margin: 0;
  z-index: 999;
}

.li-with-submenu {
  background-color: var(--background-primary-color);
}

.homeLink {
  background: linear-gradient(
    to right,
    var(--linear-primary-color),
    var(--linear-secondary-color)
  );
}

.nav-link:hover {
  background-color: var(--text-highlight-background-color);
}

.navToggleBtn {
  display: none;
  position: absolute;
}

.burgerIcon::before {
  content: '☰';
  font-size: 9vw; 
  color: var(--special-text-primary-color);
}

.hidden {
  display: none;
}

@media only screen and (orientation: landscape) {
  .navbar-ul {
    display: flex;
    flex-wrap: nowrap;
  }

  .li-with-submenu {
    width: min-content;
  }

  .filler {
    width: 100%;
    background-color: var(--background-primary-color);
    height: 3.5rem;
  }

  .filler:hover {
    background-color: var(--background-primary-color) !important;
  }
  
}

@media only screen and (orientation: portrait) {
  .navbar {
    height: auto;
    z-index: 999;
  }

  .nav-link {
    padding: 0.5rem;
    float: none;
    font-size: 2rem;
    font-weight: 350;
  }

  .homeLink {
    min-height: 2rem;
    font-size: 6vw;
    padding: 2vw;
  }

  .navToggleBtn {
    display: block;
    right: 3vw;
    padding: 0 1vw 0 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .filler {
    display: none;
  }

}
