.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  float: left;
  width: 100%;
  margin-right: -100%;
  display: block;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 12%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  margin: 0;
  border-radius: 0;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 2rem;
  height: 0.3rem;
  margin: 0 0.2rem;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 5rem;
  left: 15%;
  z-index: 10;
  padding: 1rem;
  color: #fff;
  text-align: left !important;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.carousel-img {
  display: block;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  object-position: center;
}

.carousel-caption > .reusable-button-link {
  padding: 0.2rem 0.4rem;
  margin: 1rem 0 2rem 0;
}

@media only screen and (orientation: portrait) {
  .carousel-img {
    height: 28rem;
  }
  .carousel-caption {
    bottom: 1rem;
    padding: 0 0.4rem 0.8rem 0.4rem;
  }

  .carousel-caption h1,
  .carousel-caption p,
  .carousel-caption .reusable-button-link {
    margin: 0.5rem 0;
  }

  .carousel-indicators {
    margin: 0.1rem;
  }
}
