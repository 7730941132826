.main-points-container {
  margin: 0 0 1rem 0;
}

.main-point-card-container {
  display: flex;
  justify-content: space-around;
  margin: auto;
  align-items: center;
  height: fit-content;
}

.main-point-card-container-even {
  flex-direction: row-reverse;
  background-color: var(--global-background-secondary-color);
}

.main-point-card-container-odd {
  flex-direction: row;
}

.main-point-text-container {
  padding: 1rem;
  text-align: left;
  max-width: 55%;
}

.main-point-text-container p {
  font-size: 1.2em;
}

.main-point-text-container h2 {
  font-size: 1.8em;
}

.main-point-image-container{
  padding: 1rem;
}

.main-point-card-image { 
  height: auto;
  width: 100%;
  object-fit: cover;
}

@media only screen and (orientation: portrait) {
  .main-point-card-container-even,
  .main-point-card-container-odd {
    flex-direction: column-reverse;
  }

  .main-point-text-container {
    max-width: 98%;
  }

  .main-point-image-container{
    padding: 0.5rem;
  }

  .main-point-image-container, 
  .main-point-card-image {
    max-width: 100%; 
  }
}