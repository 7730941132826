:root {
  --gray-800: #2d3748;
  --gray-700: #4a5568;
  --gray-300: #e2e8f0;
  --gray-200: #edf2f7;
  --gray-100: #f7fafc;
}

Form {
  text-align: left;
}

label {
  font-weight: bolder;
  display: flex;
  margin: 1rem 0 0.5rem;
}

label.checkbox {
  font-weight: normal;
}

input[type="email"],
input[type="text"],
input[type="password"] {
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  width: 90%;
  border: 2px solid var(--gray-200);
  background-color: var(--gray-100);
  color: var(--gray-800);
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="checkbox"] {
  display: inline-block;
  height: 1.5rem;
  font-size: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid var(--gray-300);  
  background-color: white;
  align-self: center;
  margin-right: 0.5rem;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-image: url("../assets/svg/tick.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--gray-700);
  border: 2px solid var(--gray-700);
}

input[type="checkbox"]:focus-visible,
input[type="checkbox"]:checked:focus-visible {
  border-color: var(--focus-ring-color);
}

.submit-container {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.submit-btn {
  padding: 0.5rem 1.25rem;
  width: 50%;
  font-size: 1rem;
  border-radius: 10px;
  margin: 0.5rem 0;
  background-color: var(--gray-700);
  border: 2px solid var(--gray-700);
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  line-height: initial;
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
    transform 200ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.disabled-btn {
  background-color: #cccccc;
  color: #afafaf;
  cursor: wait;
}

@media (hover: hover) {
  .submit-btn:hover {
    cursor: pointer;
    background-color: var(--gray-800);
  }

  .disabled-btn:hover {
    background-color: #cccccc;
    color: #afafaf;
    cursor: wait;
  }
}

.error {
  font-size: 12px;
  color: red;
  margin-top: 0.25rem;
}

@media only screen and (orientation: portrait) {
  .submit-btn {
    width: 98%;
  }
}