@import "./global-variables.css";

.footer {
  color: var(--special-text-primary-color);
  background-color: var(--background-primary-color);
  flex-shrink: 0;
}

.footer__top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 70%;
  margin: 0 auto;
}

.footer__infodiv {
  width: 48%;
  padding: 0 1% 0 1%;
  text-align: left;
}

.footer__infodiv > img {
  display: block;
  margin: 0.5rem auto;
}

.infodiv__title {
  font-size: larger;
  font-weight: bold;
}

.footer__infodiv > p {
  margin: 0 auto;
  line-height: 1.6;
}

.footer__infodiv > p:first-child {
  margin: 0.5rem auto;
}

.footer__infodiv > p:last-child {
  margin: 0 auto 0.5rem;
}

.school-description {
  text-align: justify;
  line-height: 1.4 !important;
}

.footer__bottom, .dan{
  background: linear-gradient(
    to right,
    var(--linear-primary-color),
    var(--linear-secondary-color)
  );
  padding: 0.5rem;
}

.footer__bottom > p {
  margin: 0 0 0.5rem 0;
}


.dan > p {
  border-top: 1px;
  border-top-style: double;
  border-color: var(--special-text-secondary-color);
  margin: 0 0 0.5rem 0;
  padding: 0.5rem 0;
}

.footer__bottom > a {
  margin: 0 1rem;
}

@media only screen and (orientation: portrait) {
  .footer__top {
    flex-direction: column;
    max-width: 96%;
  }

  .footer__infodiv {
    width: 98%;
  }

  .footer__bottom > a {
    margin: 0 0.1rem;
    font-size: 3.8vw;
  }
}
