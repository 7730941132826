:root {
  --linear-primary-color: #5356da;
  --linear-secondary-color: #3d3fac;
  --background-primary-color: #5f5f5fe0;
  --special-text-primary-color: #dbdbdb;
  --special-text-secondary-color: #84afcb;
  --text-highlight-background-color: #111;
  --global-background-color: #f4f9fb;
  --global-background-secondary-color: #a0b7c7;
  --green-background-color: #7fc801;
  --green-text-color: #446b00;
}
