.cookie-consent-wrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background-color: #333;
  color: var(--special-text-primary-color);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 998;
}

.cookie-consent-wrapper * {
  margin: 0;
  text-align: left;
}

.cookie-consent-container {
  display: flex;
  flex-direction: row;
}

.cookie-consent-container > p {
  margin: 0 1rem 0 0;
}

.cookie-consent-button {
  background-color: #ff5500;
  color: var(--special-text-primary-color);
  border: none;
  padding: 8px 16px;
  font-size: 1.1rem;
  cursor: pointer;
}

.cookie-consent-button:hover {
  background-color: #ff4400;
}

.cookie-consent-button:focus {
  outline: none;
}

.consent-hidden {
  display: none;
}

@media only screen and (orientation: portrait) {
  .cookie-consent-wrapper {
    width: 90%;
  }
  .cookie-consent-container{
    flex-direction: column;
  }
}