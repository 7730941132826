@import "./global-variables.css";

.external-icons-container {
  position: relative;
  width: 100%;
}

.external-icon {
  width: 1.8rem;
  max-width: 15%;
  height: auto;
  margin: 0.2rem;
  border: 2px solid transparent;
  padding: 2px;
  border-radius: 8px;
  transition: border-color 0.3s;
}

.external-icon:hover {
    border-color: var(--text-highlight-background-color);
  }