.reusable-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: left;
}

.reusable-card-details {
  display: flex;
  align-items: flex-start;
}

.reusable-card-image {
  width: 200px;
  height: auto;
  border-radius: 5px;
  margin: 1.2rem 1rem 0.5rem 0;
  float: left;
}

.reusable-card-name {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 0.2rem;
  font-variant: small-caps;
}

.tutor-description {
  text-align: left;
}

.reusable-card-description br {
  margin-bottom: 1rem;
}

@media only screen and (orientation: portrait) {
  .reusable-card-image {
    width: 120px;
  }
}
