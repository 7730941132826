/*see if importing the styles brakes something:*/
@import "./global-variables.css";


body {
  margin: 0;
  background-color: var(--global-background-secondary-color);
  font-family: 'EB Garamond', serif;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainWrapper {
  margin-top: 3.5rem;
  flex-grow: 1;
}

h1, h2, h3, h4, h5, h6 {
  font-variant: small-caps;  
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--global-background-color);
  max-width: 70%;
  margin: 0 auto;
  padding: 0 0 1rem 0;
}

.inside-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.smallIcon {
  height: 1.2rem;
}

button {
  margin: 0 0.5rem 0 0.5rem;
  border: 1px solid;
  border-radius: 0.3rem;
}

.cover-image {
  width: 100%;
}

h1 {
  margin: 2rem auto 2rem auto;
}

h3 {
  text-align: left;
  margin-bottom: 0.2rem;
}

.info-wrapper {
  padding: 0 1rem;
}

.info-wrapper > p {
  text-align: left;
}

.info-wrapper > p br {
  margin-bottom: 1rem;
}

.info-wrapper > ul {
  text-align: left;
  list-style-type: none;
  padding: 0;
  font-weight: bold;
}

.centered-info {
  text-align: center !important;
  font-size: larger;
}

hr {
  margin: 1.5rem auto;
  width: 80%;
  border-top: 2px;
  border-image: linear-gradient(
      to right,
      transparent,
      var(--linear-primary-color),
      var(--linear-secondary-color),
      var(--linear-primary-color),
      transparent
    )
    1 fill;
  border-style: solid;
}

.divider-line {
  width: 25%;
  min-width: 300px;
  height: 3px;
  background: linear-gradient(
    to right,
    var(--linear-primary-color),
    transparent
  );
}

a {
  color: var(--special-text-secondary-color);
}

a:hover {
  color: var(--special-text-primary-color);
}

.view-pdf {
  width: 100%;
  height: 800px;
}

.reusable-button-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid var(--linear-secondary-color);
  background: linear-gradient(
    to right,
    var(--linear-primary-color),
    var(--linear-secondary-color)
  );
  text-decoration: none;
  color: var(--special-text-secondary-color);
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.reusable-button-link:hover {
  background: var(--green-background-color);
  color: var(--green-text-color);
  border-color: var(--green-background-color);
}

.container-half-of-parent{
  width: 50%;
}

.text-with-shadow {
  text-shadow: 0 0 6px var(--text-highlight-background-color);
}

.basic-image-style {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem;
}

@media only screen and (orientation: portrait) {
  .mainWrapper {
    margin-top: 11vw;
  }

  .page-wrapper {
    max-width: 98%;
    padding-top: 1rem;
  }

  .wrapper-with-cover-image {
    padding-top: 0 !important;
  }

  .cover-image {
    width: 102%;
  }

  .inside-wrapper {
    flex-direction: column;
  }

  .container-half-of-parent{
    width: 100%;
  }

}
